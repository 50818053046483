.dark-mode {
  --color-black:#fff;                  // text color, mostly
  --color-white:#1e1e1e;               // background color
  --color-white-rgb: 30, 30, 30;

  --text-color: color-mix(in srgb, var(--color-black) 85%, transparent);

  --border-color: #888888;
  --border-color-alt: #e7e8fc;

  --antd-arrow-background-color: var(--bg-color);


  .ant-btn:hover,
  .ant-btn:focus {
    color: var(--text-color);
    background: var(--color-blue);
    border-color: var(--color-blue);
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: color-mix(in srgb, var(--color-black) 50%, var(--color-white));
    border-color: var(--border-color);
    background: var(--bg-color);
    text-shadow: none;
    box-shadow: none;
  }

  .ant-divider {
    border-top-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);
  }

  .ant-dropdown-menu {
    background: color-mix(in srgb, var(--bg-color) 90%, var(--text-color));
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background: var(--border-color);
  }

  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background: color-mix(in srgb, var(--border-color) 50%, transparent);
  }

  .ant-modal-footer {
    border-top-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);
  }

  .ant-modal-header {
    border-bottom-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: color-mix(in srgb, var(--color-blue-alt) 50%, transparent);
  }

  .ant-picker-header {
    border-bottom-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);

    button {
      color: color-mix(in srgb, currentColor 50%, transparent);
    }
  }

  .ant-picker-header-view {
    button {
      color: currentColor;
    }
  }

  .ant-picker-panel {
    border-color: transparent;

    .ant-picker-footer {
      border-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);
    }
  }

  .ant-picker-panel-container {
    background: color-mix(in srgb, var(--bg-color) 90%, var(--text-color));
  }

  .ant-popover-inner {
    background: color-mix(in srgb, var(--bg-color) 90%, var(--text-color));
  }

  .ant-select-clear {
    color: currentColor;
    background: transparent;
  }

  .ant-select-dropdown {
    background: color-mix(in srgb, var(--bg-color) 90%, var(--text-color));
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--border-color);
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      color: var(--text-color);
      background: var(--border-color);
      border-color: transparent;
    }
  }

  .ant-switch {
    background: var(--border-color);
  }

  .ant-switch-checked {
    background: var(--color-blue-alt);
  }

  .ant-table-tbody {
    > tr > td {
      border-color: color-mix(in srgb, var(--border-color) 50%, transparent);
    }

    > tr.ant-table-row:hover > td,
    > tr > td.ant-table-cell-row-hover {
      background: color-mix(in srgb, var(--bg-color) 90%, var(--text-color));
    }

    > tr.ant-table-placeholder:hover > td {
      background: color-mix(in srgb, var(--text-color) 5%, transparent);
    }
  }

  .ant-table-thead {
    > tr > th {
      background: color-mix(in srgb, var(--text-color) 5%, transparent);
      border-color: color-mix(in srgb, var(--border-color) 50%, transparent);
    }

    th.ant-table-column-has-sorters:hover {
      background: color-mix(in srgb, var(--text-color) 15%, transparent);
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: color-mix(in srgb, var(--border-color-alt) 20%, transparent);
  }

  .dark-theme .fr-second-toolbar {
    border-color: var(--border-color);
    background: var(--bg-color);
  }

  .fr-box.dark-theme.fr-basic {
    .fr-element {
      color: var(--text-color);
    }

    .fr-wrapper {
      color: var(--text-color);
      background: var(--bg-color);
      border-color: var(--border-color);
      border-bottom-color: color-mix(in srgb, var(--border-color) 15%, transparent);
    }
  }

  .fr-toolbar.dark-theme {
    color: var(--text-color);
    background: var(--bg-color);
    border-color: var(--border-color);
  }
}

/*
body bgr: #1E1E1E
left sidebar bgr: #1E1E1E
left sidebar icons: #FFF
header bgr: #1E1E1E
header breadcrumb: #FFF
top right header profile title: #888888
main body title: #FFF
description in body: #888888
tiles outline/border: #888888
tiles color on hover: #2a7ee1
tile Title: #FFF
tile description: #888888
simple text: #FFF
tile icon: #2a7ee1
text link ''more'': #2a7ee1
tile all text on hover + icon + more: #FFF
back button: #87929B
Ticket Escalations
search field icon: #FFF
search field outline/border: 30% #E7E8FC
search field placeholder: #FFF
ticket row outline/border (and all input fields outline/border): 40% #E7E8FC
ticket text details: #888888
ticket text details 2nd row: #FFF
badges, CTA buttons: the same colors as it is in light mode
*/
