body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  @supports (font-variation-settings: normal) {
    font-family: InterVariable, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

body, h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
}

.ant-typography {
  color: var(--text-color);
}

h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  color: var(--text-color);
}

h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  color: var(--text-color);
}

.ant-avatar {
  border: 2px solid var(--color-blue);
  box-sizing: content-box;
}

.ant-btn:not(.ant-btn-link):not(.ant-btn-primary):not(.ant-btn-text):not(.btn-black) {
  color: var(--text-color);
  background: var(--bg-color);
  border-color: var(--border-color);
}

.ant-btn.ant-btn.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: color-mix(in srgb, currentColor 50%, transparent);
}

.ant-btn-primary {
  background: var(--color-blue-alt);
  border-color: var(--color-blue-alt);

  &:focus, &:hover {
    background: color-mix(in srgb, var(--color-blue-alt) 90%, black 10%);
    border-color: color-mix(in srgb, var(--color-blue-alt) 90%, black 10%);
  }
}

.ant-btn-text {
  color: var(--text-color);
}

.ant-card {
  color: var(--text-color);
  background: var(--color-white);
}

.ant-card-bordered {
  border-color: var(--border-color);
}

.ant-card-meta-description {
  color: color-mix(in srgb, var(--text-color) 85%, transparent);
}

.ant-card-meta-title {
  color: var(--text-color);
}

.ant-checkbox-wrapper {
  color: var(--text-color);
}

.ant-divider {
  border-top-color: color-mix(in srgb, var(--color-black) 5%, transparent);
}

.ant-dropdown {
  color: var(--text-color);
}

.ant-dropdown-menu {
  background: var(--color-white);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--text-color);
}

.ant-empty-normal,
.ant-empty-small {
  color: color-mix(in srgb, var(--color-black) 50%, transparent)
}

.ant-form {
  color: var(--text-color);

  label {
    font-size: 12px;
    font-weight: 700;
  }
}

.ant-form-item,
.ant-form-item-label > label {
  color: var(--text-color);
}

.ant-form-vertical {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

.ant-input,
.ant-input-affix-wrapper {
  color: var(--text-color);
  background: var(--color-white);
  border-color: var(--border-color);
}

.ant-layout {
  background: transparent; // overwrites tinyColor.less
}

.ant-list-item {
  color: var(--text-color);
}

.ant-modal {
  color: var(--text-color);
}

.ant-modal-content,
.ant-modal-header {
  background-color: var(--color-white);
}

.ant-modal-title {
  color: var(--text-color);
}

.ant-pagination-item {
  background-color: var(--color-white);
  border-color: var(--border-color);

  a {
    color: var(--text-color);
  }
}
.ant-pagination-item-active {
  border: none;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-prev button,
.ant-pagination-next button {
  color: var(--text-color);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: color-mix(in srgb, var(--color-black) 25%, transparent);
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: var(--color-white);
  border-color: var(--border-color);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: color-mix(in srgb, var(--color-black) 25%, transparent);
  border-color: var(--border-color);
}

.ant-picker {
  color: var(--text-color);
  background-color: var(--color-white);
  border-color: var(--border-color);

  input {
    color: currentColor;
  }
}

.ant-picker-cell {
  color: color-mix(in srgb, currentColor 50%, transparent);
}

.ant-picker-cell-in-view {
  color: currentColor;
}

.ant-picker-clear {
  color: currentColor;
  background-color: var(--color-white);

  &:hover {
    color: var(--color-blue-alt);
  }
}

.ant-picker-content th {
  color: var(--text-color);
}

.ant-picker-dropdown {
  color: var(--text-color);
}

.ant-picker-header {
  color: currentColor;
}

.ant-picker-panel-container {
  background: var(--color-white);
}

.ant-radio-button-wrapper {
  color: var(--text-color);
  background: var(--color-white);
  border-color: var(--border-color);
}

.ant-radio-group {
  color: var(--text-color);
}

.ant-popover,
.ant-popover-inner-content {
  color: var(--text-color);
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: var(--color-white);
}

.ant-popover-inner {
  background-color: var(--color-white);
}

.ant-select {
  color: var(--text-color);

  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--color-white);
    border-color: var(--border-color);
  }
}

.ant-select-arrow {
  color: currentColor;
}

.ant-select-dropdown {
  color: var(--text-color);
  background-color: var(--color-white);
}

.ant-select-item {
  color: var(--text-color);
}

.ant-select-item-option-disabled {
  color: color-mix(in srgb, var(--text-color) 50%, transparent);
}

.ant-select-multiple {
  .ant-select-selector {
    padding-left: 0;
  }
}

.ant-table {
  color: var(--text-color);
  background: var(--bg-color);
}

.ant-table-tbody {
  > tr > td {
    border-color: var(--border-color);
  }
}

.ant-table-thead {
  > tr > th {
    color: currentColor;
    background: var(--bg-color);
    border-color: var(--border-color);
  }
}

.ant-tabs-tab-btn {
  color: var(--text-color);
}

.participants-dropdown {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    gap: 4px; // magic number
  }
}

p{
   margin-bottom: 0;
}
