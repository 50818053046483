@use "util" as util;

// Frame 1649
.conversation-create-modal,
.conversation-update-modal,
.profile-2fa-modal,
.profile-notifications-modal {
  label {
    margin-left: 16px; // magic number
  }

  h1, p {
    text-align: center;
  }

  p {
    font-weight: 500;
  }

  nz-select {
    width: 100%;

    ::ng-deep .ant-select-selection-item .ant-avatar {
      align-self: center;
      margin-right: 4px;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    padding: var(--padding-xl);
    border-radius: 12px;

    @include util.mq(lg) {
      padding: calc(var(--padding-xl) * 2);
    }
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-select {
    padding: 8px;
    background: var(--body-bg);
    border: 1px solid var(--border-color);
    border-radius: 24px; // magic number

    .ant-avatar {
      border: 0;
    }
  }

  .ant-select-arrow {
    font-size: 14px; // magic number
  }

  .ant-select-selector.ant-select-selector { // extra specificity
    background: transparent;
    border: 0;
    box-shadow: none !important; // overwrites focus style
  }

  .ant-select-selection-item {
    height: auto;
    align-items: center;
    gap: 4px; // magic number
    padding: 2px 6px; // magic numbers
    background: var(--color-white);
    border: 1px solid var(--border-color);
    border-radius: 16px; // magic number
    color: var(--black);
    font-size: 12px;
  }
}


.main-app-menu.ant-menu {
  color: var(--color-black);
  background: var(--color-white);
}


.comment-content,
.ticket-description {
  img {
    max-width: 100%;
  }
}

.profile-2fa-modal {
  label {
    height: auto;
    margin-left: 0;
  }
}

.meeting-modal {
  max-width: 700px;
}
