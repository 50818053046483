@media print {
  :root {
    --color-black: #000;
    --color-white: #fff;
  }

  .is-printing-invoice { // extra specificity
    accountmanager,
    .ant-layout-side,
    .invoice-head,
    .main-layout-header {
      display: none !important;
    }

    .inner-layout {
      padding: 0 !important;

      > .ant-row-start {
        display: none !important;
      }
    }

    .invoice-container {
      max-width: none !important;
      margin: 0 !important;
      padding: 0 !important;
      color: var(--color-black) !important;
      background: transparent !important;
      box-shadow: none !important;

      * {
        color: inherit !important;
      }

      .empist-invoice-details {
        flex-direction: row !important;
        justify-content: space-between;
      }
    }
  }
}
