// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@black: #142837;

@heading-1-size: 32px;
@layout-header-background: transparent;
@layout-header-height: 79px;
@layout-header-padding: 0 @padding-lg;
@typography-title-font-weight: 700;

@table-header-bg: @table-bg;
@table-padding-vertical: 4px;
// @table-border-color: transparent;

@tag-border-radius: 12px; // magic number
@tag-line-height: 24px; // magic number

@modal-mask-bg: fade(@black, 50%);

.ant-layout-sider-children {
  width:100%;
}
