.dark-mode {
  .btn-black {
    color: var(--text-color);
    background: var(--bg-color);
    border-color: var(--border-color);

    &:hover {
      color: var(--color-black);
      background: var(--color-blue-alt);
      border-color: transparent;
    }
  }
}

.btn-black{
  background: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
  padding-left: 30px;
  padding-right: 30px;
  transition: all .3s ease;
  &:focus,
  &:hover{
    background: color-mix(in srgb, var(--color-black) 90%, black 10%);
    border-color: color-mix(in srgb, var(--color-black) 90%, black 10%);
    color: var(--color-white);
  }
}

.back-button{
  display:block;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor:pointer;
  color: var(--color-gray-alt);
  font-size: 14px;
  transition: all .3s ease;
  &:focus,
  &:hover{
    color: var(--color-gray);
  }
  .anticon{
    color:var(--color-black);
  }
}
.edit-link{
  display: inline-block;
  color: var(--color-gray-alt);
  font-size: 14px;
  transition: all .3s ease;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-self: flex-end;
  margin-left:auto;
  &:hover{
    color: var(--color-gray);
  }
  .anticon{
    color:var(--color-black);
  }
}

.ant-btn-round{
  height:44px;
  padding-left: 35px;
  padding-right: 35px;
}
