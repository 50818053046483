@use "util" as util;

.emp-form-wrapper {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--padding-sm);
  padding-left: var(--padding-sm);

  .actions-container {
    text-align: right;

    button {
      height: auto;
      padding: var(--padding-sm) var(--padding-lg); // magic numbers
    }
  }

  .box {
    padding: var(--padding-sm);
    border: 1px solid var(--color-gray-plain);
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, .1);
    border-radius: 8px;

    @include util.mq(lg) {
      padding: var(--padding-xl);
    }
  }

  .emp-form-form-item {
    row-gap: var(--padding-xs);

    app-phone-input {
      .ant-input-group {
        transition: border-color 300ms;
        border-bottom: 1px solid var(--border-color);

        &:hover {
          border-color: #40a9ff; // ng-zorro default
        }
      }

      .ant-select-in-form-item {
        width: auto;
      }

      .ant-select-selector {
        padding-left: 0;
      }

      nz-select-item {
        img {
          margin-left: 0;
        }
      }
    }

    label.ant-form-item-required {
      flex-flow: row-reverse;
      column-gap: .5em;
    }

    .ant-input,
    .ant-picker {
      padding-left: 0;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-picker {
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
      box-shadow: none !important;
    }

    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
        box-shadow: none !important;
      }
    }

    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        padding-left: 0;
      }
    }

    .fr-box.fr-basic .fr-element {
      padding-right: 0;
      padding-left: 0;
    }

    .fr-second-toolbar {
      border-color: var(--border-color);
      border-right: none;
      border-left: none;
      border-radius: 0;
    }

    .fr-toolbar {
      .fr-btn-grp,
      .fr-newline {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .fr-toolbar,
    .fr-wrapper.fr-wrapper {
      border: none;
    }
  }
}
