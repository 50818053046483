@use "sass:map";

$breakpoints: (
  sm:  576px,
  md:  768px,
  lg:  992px,
  xl:  1200px,
  xxl: 1600px
);

@mixin mq($mq-breakpoint, $mq-vertical: null, $mq-breakpoints: $breakpoints) {
  @if map.has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map.get($mq-breakpoints, $mq-breakpoint);
  }

  @if $mq-vertical and map.has-key($mq-breakpoints, $mq-vertical) {
    $mq-vertical: map.get($mq-breakpoints, $mq-vertical);
  }

  @if $mq-vertical {
    @media screen and (min-width: #{$mq-breakpoint}) and (min-height: #{$mq-vertical}) {
      @content;
    }
  } @else {
    @media screen and (min-width: #{$mq-breakpoint}) {
      @content;
    }
  }
}


@mixin scrollbar($extend: true) {
  @if $extend {
    @extend %scrollbar;
  } @else {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-gray);
    }
  }
}

%scrollbar {
  @include scrollbar($extend: false);
}


$padding: var(--padding-sm);
@include mq("sm") {
  $padding: var(--padding-sm);
}

@include mq("md") {
  $padding: var(--padding-md);
}

@include mq("lg") {
  $padding: var(--padding-lg);
}

%overlay {
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--color-white-rgb), .7);
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, .1);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
