@use "util" as util;

body {
  @include util.mq(lg, md) {
    overflow: hidden;

    > app-root {
      height: 100%;
      display: block;
    }
  }
}

.main-layout-header {
  z-index: 2;
  position: sticky;
  top: 0;
  background: var(--color-white);
  border-bottom: solid 1px var(--border-color);
  padding: 0;
}

.header{
  padding: var(--padding-sm);

  @include util.mq(sm){
    padding: var(--padding-sm);
  }
  @include util.mq(md){
    padding: var(--padding-md);
  }
  @include util.mq(lg){
    padding: var(--padding-lg);
  }
}

.inner-layout {
  padding: var(--padding-sm);
  padding-bottom: 80px;

  @include util.mq(sm){
    padding: var(--padding-sm);
    padding-bottom: 80px;
  }
  @include util.mq(md){
    padding: var(--padding-md);
    padding-bottom: 80px;
  }
  @include util.mq(lg){
    padding: var(--padding-lg);
  }
}

.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
