@use "sass:color";

@function hexToRGB($hex) {
  @return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb), color.channel($hex, "blue", $space: rgb);
}

$--color-black:      #142837;
$--color-blue:       #2a7ee1;
$--color-blue-alt:   #0070C9;
$--color-white:      #ffffff;
$--color-gray-plain: #cccccc;
$--color-gray:       #b8bbc3;
$--color-gray-1:     #ececec;
$--color-gray-7:     #78868c;
$--color-gray-8:     #3e3e3e;
$--body-bg:          #ffffff;
$--border-color:     #e7e8fc;
$--color-gray-alt:   #87929B;
$--color-light-blue: #e0edff;
$--color-light-blue: #d3ecff;
$--color-green:      #37A326;
$--color-orange:     #F2812F;
$--color-gray-3:     #828C93;
$--color-dark-blue:  #182739;
$--color-red:        #D34354;

$--color-removed-bg:   #FFD9E4;
$--color-removed-text: #A02A49;
$--color-added-bg:     #D0ECDD;
$--color-added-text:   #228757;
$--color-marked-for-delete-bg:  #FFF6DA;
$--color-marked-for-delete-text:#665E48;

$--color-activated-bg:       #d3ecff;
$--color-activated-text:     #182739;
$--color-deactivated-bg:     #ececec;
$--color-deactivated-text:   #78868C;

$--color-filter-background: #ffffff;
$--color-filter-border:     #CDDEFA;

$--color-event: #12d2f0;

$--color-badge-new: #ed0b80;

$--color-ticket-status: #4aec6f;

$--header-height: 79px;

$--sider-width: 60px;

$--padding-xs:  4px; // theme default, @padding-xss
$--padding:     8px; // theme default, @padding-xs
$--padding-sm: 12px; // theme default, @padding-sm
$--padding-md: 16px; // theme default, @padding-md
$--padding-lg: 24px; // theme default, @padding-lg
$--padding-xl: 36px;


:root {
  --color-black:        #{$--color-black};
  --color-black-rgb:    #{hexToRGB($--color-black)};
  --color-blue:         #{$--color-blue};
  --color-blue-rgb:     #{hexToRGB($--color-blue)};
  --color-white:        #{$--color-white};
  --color-white-rgb:    #{hexToRGB($--color-white)};
  --color-gray:         #{$--color-gray};
  --color-gray-1:       #{$--color-gray-1};
  --color-gray-7:       #{$--color-gray-7};
  --color-gray-rgb:     #{hexToRGB($--color-gray)};
  --body-bg:            #{$--body-bg};
  --border-color:       #{$--border-color};
  --color-gray-alt:     #{$--color-gray-alt};
  --color-blue-alt:     #{$--color-blue-alt};
  --color-gray-plain:   #{$--color-gray-plain};
  --color-light-blue:   #{$--color-light-blue};
  --color-green:        #{$--color-green};
  --color-orange:       #{$--color-orange};
  --color-orange-rgb:   #{hexToRGB($--color-orange)};
  --color-gray-3:       #{$--color-gray-3};
  --color-gray-3:       #{$--color-gray-3};
  --color-filter-background: #{$--color-filter-background};
  --color-filter-border: #{$--color-filter-border};
  --color-dark-blue:    #{$--color-dark-blue};
  --color-red:          #{$--color-red};

  --color-removed-bg:     #{$--color-removed-bg};
  --color-removed-text:   #{$--color-removed-text};
  --color-added-bg:       #{$--color-added-bg};
  --color-added-text:     #{$--color-added-text};
  --color-marked-for-delete-bg:   #{$--color-marked-for-delete-bg};
  --color-marked-for-delete-text: #{$--color-marked-for-delete-text};
  --color-activated-bg:   #{$--color-activated-bg};
  --color-activated-text: #{$--color-activated-text};
  --color-deactivated-bg:   #{$--color-deactivated-bg};
  --color-deactivated-text: #{$--color-deactivated-text};

  --color-event: #{$--color-event};

  --color-badge-new: #{$--color-badge-new};

  --color-ticket-status: #{$--color-ticket-status};

  --text-color: var(--color-black);
  --bg-color: var(--color-white);

  --header-height: 79px;
  --sider-width:   60px;

  --padding-xs:  4px; // theme default, @padding-xss
  --padding:     8px; // theme default, @padding-xs
  --padding-sm: 12px; // theme default, @padding-sm
  --padding-md: 16px; // theme default, @padding-md
  --padding-lg: 24px; // theme default, @padding-lg
  --padding-xl: 36px;

  --screen-md:  768px;
  --screen-lg:  992px;
  --screen-xl: 1200px;
  --screen-xxl: 1920px;

  --scrollbar-width: 8px;
}
